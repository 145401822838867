import { Container, Footer as MantineFooter } from '@mantine/core'
import React from 'react'

import { MenuContent as MenuContentType } from '@/types'

interface Props {
  content: MenuContentType
}

export default function Menu({ content }: Props) {
  return (
    <MantineFooter fixed={false} height="auto" py="xl">
      <Container size="xl">
        <div dangerouslySetInnerHTML={{ __html: content.customContent }}></div>
      </Container>
    </MantineFooter>
  )
}
